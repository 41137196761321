import React from 'react'
import Helmet from 'react-helmet'

import './index.css'
import 'pretty-checkbox/dist/pretty-checkbox.min.css'

import Header from '../components/Header'
import Footer from '../components/Footer'

const TemplateWrapper = ({ children }) => (
  <div>
    <Helmet
      title="🌹 NYC-DSA event check in"
      meta={[{ name: 'description', content: 'Check in to a NYC-DSA event' }]}
    />
    <div className="container">
      <Header />
      {children}
      <Footer />
    </div>
  </div>
)

export default TemplateWrapper
