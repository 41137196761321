import React, { Component } from 'react'

import logo from '../img/logo.png'

class Header extends Component {
  render() {
    return (
      <header>
        <img alt="NYC-DSA logo" src={logo} />
        <span>New York City Democratic Socialists of America</span>
      </header>
    )
  }
}

export default Header
