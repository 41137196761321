import http from 'axios'
import React, { Component } from 'react'
import Layout from '../layouts/index.js'

import CheckInForm from '../components/CheckInForm'
import events from '../data/events.json'
import { isToday } from '../util/dates'

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: this.convertEventDates(events),
    }
  }

  convertEventDates = events =>
    events.map(e => ({
      ...e,
      startDate: new Date(e.startDate),
      endDate: new Date(e.endDate),
    }))

  handleSubmit = data => {
    const url = 'https://im-here-google-forms-api.herokuapp.com/'
    // const url = 'http://localhost:3000'
    // console.log('data', data)
    return http.get(url, { params: data }) // api endpoint is GET
  }

  isKioskMode = () => {
    if (typeof window !== `undefined`) {
      const { search } = window.location
      return search.includes('kioskMode=true')
    }

    return false
  }

  render() {
    const today = this.state.events.filter(e => isToday(e.startDate))
    return (
      <Layout>
        <main>
          <CheckInForm
            events={today}
            kioskMode={this.isKioskMode()}
            onSubmit={this.handleSubmit}
          />
        </main>
      </Layout>
    )
  }
}

export default Home
