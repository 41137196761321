import React, { Component } from 'react'

class Footer extends Component {
  state = {
    privacyPolicyShown: false,
  }

  togglePrivacyPolicy = e => {
    e.preventDefault()
    const current = this.state.privacyPolicyShown

    this.setState({ privacyPolicyShown: !current })
  }

  render() {
    const { privacyPolicyShown } = this.state
    return (
      <footer>
        <span role="img" aria-label="A rose emoji">
          🌹
        </span>
        <div>
          <button onClick={this.togglePrivacyPolicy}>
            {privacyPolicyShown ? 'Hide' : 'Show'} privacy policy
          </button>
          {privacyPolicyShown && (
            <section id="privacy-policy">
              <h1>Privacy policy</h1>
              <p>
                We promise not to do anything messed up with your data. We
                assume you are a member of NYC-DSA and therefore, the
                organization already has your email address. We use this data to
                track attendance across events at an aggregate and personal
                level. This helps us analyze which times/locations/topics are
                most engaging for our membership and also to make members aware
                of upcoming events that they might like. We do not share any of
                this information or information derived from it with any
                organizations outside of NYC-DSA.
              </p>
              <p>
                We do not track your location beyond associating your presence
                with an event with a known location. We don’t run any third
                party Javascript on this page.
              </p>
              <p>
                At the moment, this data is stored in a Google spreadsheet with
                tightly restricted read access.
              </p>
            </section>
          )}
        </div>
      </footer>
    )
  }
}

export default Footer
