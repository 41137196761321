export const isToday = d => {
  const today = new Date()

  const datesMatch = d.getDate() === today.getDate()
  const monthsMatch = d.getMonth() === today.getMonth()
  const yearsMatch = d.getYear() === today.getYear()

  if (datesMatch && monthsMatch && yearsMatch) return true
  return false
}
